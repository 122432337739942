import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useRecoilValue } from 'recoil';
import { hostState } from '../../recoil/Academy/hostState';
import ErrorHelper from '../../utils/error/ErrorHelper';

export default function NotFoundLoginError() {
  const router = useRouter();
  const host = useRecoilValue(hostState);

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const queryString = router.asPath.split('?')[1];
  const query = new URLSearchParams(queryString);
  const error = new Error();

  const type = query.get('type');
  const message = query.get('message');

  useEffect(() => {
    if (type === null || message === null) {
      setIsChecked(true);
      return;
    }

    if (type === 'SNS_LOGIN_FAILED') {
      router.replace(`404/login?message=${message}`);
    }
  }, [message, router, type]);

  return <ErrorHelper error={error} reset={() => router.replace('/')} host={host} />;
}
